// TODO: test
export const formatMinutes = (minutes: number | undefined) => {
  if (!minutes) return undefined;
  return (
    `${minutes / 60 < 1 ? 0 : Math.trunc(minutes / 60)}`.slice(-2) +
    'h' +
    ('0' + (minutes % 60)).slice(-2)
  );
};
// TODO: test
export const formatDate = (date: string | undefined) => {
  if (!date) return undefined;
  const serializedDate = new Date(date);
  const day = serializedDate.getDate();
  const month = serializedDate.getMonth() + 1;
  const year = serializedDate.getFullYear().toString().slice(2);
  return `${day}.${month}.${year}`;
};
