import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { Stack } from '../../components/Core';
import EvenementCard from '../../components/EvenementCard';
import Layout from '../../components/Layout';
import SectionWithTitle from '../../components/SectionWithTitle';
import SEO from '../../components/SEO';
import groupBy from '../../utils/helpers/groupBy';
import orderBy from '../../utils/helpers/orderBy';

export type IEvenementPageTemplate = PageProps<
  Queries.PageEvenementsQuery,
  IPageContext
>;

const EvenementsPageTemplate: FCWithClassName<IEvenementPageTemplate> = (
  props
) => {
  const { data, pageContext } = props;
  const pageData = data.wpPage;
  const pageTemplateData = 'contenuEvenements' in pageData.template ? pageData.template.contenuEvenements : null;
  const evenements = data.allWpEvenement?.nodes;
  if (!pageData || !pageContext.defaultLanguage || !pageTemplateData) return null;
  const evenementsGroupedByPastOrFuture = groupBy(evenements, (evenement) => {
    if (!evenement) return undefined;
    const frFormattedDateString = evenement?.informationsEvenement?.date;
    const parts = frFormattedDateString.split('.');
    const normalizedDate = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10)
    );
    const now = new Date(Date.now());
    return normalizedDate >= now ? 'future' : 'past';
  });
  const futureEventsSortedFromClosestToFarthest = orderBy(
    evenementsGroupedByPastOrFuture.future,
    (evenement) => evenement.informationsEvenement.rawDate
  );
  const pastEventsSortedFromClosestToFarthest = orderBy(
    evenementsGroupedByPastOrFuture.past,
    (evenement) => evenement.informationsEvenement.rawDate,
    'desc'
  );
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <SectionWithTitle heading="Nos évènements à venir" className="mt-gap">
        <Stack className="w-full space-y-gap">
          {futureEventsSortedFromClosestToFarthest?.map((evenement) => {
            if (!evenement) return null;
            return (
              <EvenementCard
                key={evenement.id}
                buttonLabels={pageTemplateData}
                evenement={evenement}
                isFutureEvent
              />
            );
          })}
        </Stack>
      </SectionWithTitle>
      <SectionWithTitle heading="Nos évènements passés" className="mt-gap">
        <Stack className="w-full space-y-gap">
          {pastEventsSortedFromClosestToFarthest?.map((evenement) => {
            if (!evenement) return null;
            return (
              <EvenementCard
                key={evenement.id}
                buttonLabels={pageTemplateData}
                evenement={evenement}
              />
            );
          })}
        </Stack>
      </SectionWithTitle>
    </Layout>
  );
};

export default EvenementsPageTemplate;

export const query = graphql`
  query PageEvenements($id: String) {
    wpPage(id: { eq: $id }) {
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      language {
        locale
        slug
      }
      translations {
        language {
          code
          locale
        }
        uri
      }
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      template {
        ...on WpTemplate_PageEvenement {
          contenuEvenements {
            texteBoutonInscription
            texteBoutonTousEvenements
          }
        }
      }
    }
    allWpEvenement {
      nodes {
        id
        title
        informationsEvenement {
          adresse
          description
          date(formatString: "D.M.Y H:mm")
          rawDate: date
          duree
          lienInscription
          centre
        }
        title
        featuredImage {
          node {
            ...MediaItemFragment
            localFile {
              childImageSharp {
                gatsbyImageData(
                  breakpoints: [360, 800, 1460, 2880]
                  
                  layout: FULL_WIDTH
                )
              }
              publicURL
            }
          }
        }
      }
    }
  }
`;
