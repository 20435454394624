import { FaHourglassEnd } from '@react-icons/all-files/fa/FaHourglassEnd';
import { FaRegCalendarAlt } from '@react-icons/all-files/fa/FaRegCalendarAlt';
import { IoMdPin } from '@react-icons/all-files/io/IoMdPin';
import React from 'react';

import IconWithText from '../../components/IconWithText';
import StyledLink from '../../components/StyledLink';
import ThemedButton from '../../components/ThemedButton';
import { formatMinutes } from '../../utils/dateAndTime';
import { serializeImageForImageComponent } from '../../utils/serializeImageForImageComponent';
import { Flex, Stack } from '../Core';
import Image from '../Image';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';

export type IWordpressEvenement = Queries.PageEvenementsQuery['allWpEvenement']['nodes'][number];
interface IEvenementCardProps {
  buttonLabels:
    | {
        texteBoutonInscription: string;
        texteBoutonTousEvenements: string;
      }
    | undefined;
  evenement: IWordpressEvenement;
  isFutureEvent?: boolean;
  isHomepage?: boolean;
}

const EvenementCard: FCWithClassName<IEvenementCardProps> = (props) => {
  const {
    buttonLabels,
    evenement,
    isFutureEvent = false,
    isHomepage = false,
  } = props;
  return (
    <Flex className="relative items-center justify-center w-full py-20">
      <Image
        image={serializeImageForImageComponent({
          image: evenement.featuredImage?.node,
        })}
        className="absolute w-full h-full"
      />
      <Flex
        className="z-10 bg-backgroundMain w-[95%] md:w-[90%] lg:w-[50%] min-w-[300px] p-10 relative"
        align="center"
        direction="column"
      >
        <Text variant={TEXT_STYLES.H4} className="!text-[40px] text-center">
          {evenement.title}
        </Text>
        <Text variant={TEXT_STYLES.H5} className="text-center">
          {evenement.informationsEvenement.description}
        </Text>
        <Stack className="mt-4 space-y-6">
          <Flex
            className="self-start w-full gap-4 wrap"
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
          >
            <IconWithText
              isInline
              icon={FaRegCalendarAlt}
              description={evenement.informationsEvenement.date}
              iconClassName="w-7 h-7"
              textClassName="text-xl uppercase"
            />
            <IconWithText
              isInline
              icon={FaHourglassEnd}
              description={formatMinutes(evenement.informationsEvenement.duree)}
              iconClassName="w-7 h-7"
              textClassName="text-xl uppercase"
            />
          </Flex>
          <Stack className="self-start space-y-1 wrap">
            {evenement.informationsEvenement.centre && (
              <IconWithText
                isInline
                icon={IoMdPin}
                description={evenement.informationsEvenement.centre}
                iconClassName="w-7 h-7"
                textClassName="text-xl uppercase"
              />
            )}
            {evenement.informationsEvenement.adresse && (
              <Text className="text-center mt-2 mb-14 !text-4">
                {evenement.informationsEvenement.adresse}
              </Text>
            )}
          </Stack>
        </Stack>
        {isFutureEvent && (
          <StyledLink href={evenement.informationsEvenement.lienInscription}>
            <ThemedButton className="overlap-centered">
              {buttonLabels?.texteBoutonInscription}
            </ThemedButton>
          </StyledLink>
        )}
      </Flex>
      {isHomepage && (
        <StyledLink to="/evenements">
          {' '}
          {/* TODO: remplacer avec données de WP */}
          <ThemedButton className="overlap-centered sm:!right-[5%] text-[20px] sm:text-[30px] sm:!translate-x-0">
            {buttonLabels?.texteBoutonTousEvenements}
          </ThemedButton>
        </StyledLink>
      )}
    </Flex>
  );
};

export default EvenementCard;
